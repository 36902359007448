import { combineReducers } from 'redux';
import { deliverySlice } from './slices/DeliverySlice';
import { documentSlice } from './slices/DocumentSlice';
import { domainSlice } from './slices/DomainSlice';
import { siteSlice } from './slices/SiteSlice';
import { siteWithStockBalanceSlice } from './slices/SiteWithStockBalanceSlice';
import { splashMessageAdminSlice } from './slices/SplashMessageAdminSlice';
import { splashMessageNotificationSlice } from './slices/SplashMessageNotificationSlice';
import { statusSlice } from './slices/StatusSlice';
import { stockSlice } from './slices/StockSlice';
import { transactionSlice } from './slices/TransactionSlice';
import { userSlice } from './slices/UserSlice';

export const rootReducer = combineReducers({

    status: statusSlice.reducer,
    domains: domainSlice.reducer,
    transactions: transactionSlice.reducer,
    deliveries: deliverySlice.reducer,
    sitesWithStockBalances: siteWithStockBalanceSlice.reducer,
    stocks: stockSlice.reducer,
    users: userSlice.reducer,
    sites: siteSlice.reducer,
    documents: documentSlice.reducer,
    splashMessagesAdmin: splashMessageAdminSlice.reducer,
    splashMessageNotifications: splashMessageNotificationSlice.reducer,
});


export type ApplicationState = ReturnType<typeof rootReducer>;


import * as React from 'react';
// import { Route } from 'react-router-dom';
import { Layout as AntLayout, Row, Col } from 'antd';

import { ConfigProvider } from 'antd';
import enGB from 'antd/lib/locale-provider/en_GB';

// import { AdminFormContainer } from './AdminFormContainer';
import Navbar from './Navbar/Navbar';
import FooterLinks from './FooterLinks';
import SplashMessageModal from './SplashMessageNotifications/SplashMessageModal';


const { Header, Footer, /*Sider,*/ Content } = AntLayout;

interface Props {
	children: React.ReactNode;
}

export default function (props: Props) {

	return (
		<ConfigProvider locale={enGB}>
			<AntLayout style={{ backgroundColor: '#fff' }}>
				<Header style={{ backgroundColor: '#fff' }}>

					<Navbar />

					{/* <AdminFormContainer /> */}
				</Header>
				<Content style={{ background: '#fff', padding: 24, minHeight: 500 }}>
					{props.children}
				</Content>
				<Footer style={{ backgroundColor: '#fff', paddingLeft: '24px', paddingRight: '24px' }}>
					<br />
					<Row style={{ borderTop: '1px solid #ddd' }}>
						<Col span={24}>
							<br />
						</Col>
					</Row>
					<Row>
						<FooterLinks />


						<Col span={12} style={{ textAlign: 'right' }}>
							<img src="/images/footerlogo.png" height='40' style={{ marginBottom: '15px' }} alt="UK Fuels Ltd." /><br />
								UK Fuels Limited<br />
                                Radius Campus, Emperor Way<br />
                                Crewe Business Park <br />
                                Crewe, CW1 6BD<br />
								Company No. 02212080<br />
						</Col>
					</Row>
					<Row>
						<Col span={24} style={{ textAlign: 'center' }}>
							Copyright &copy; 2021 UK Fuels.
							</Col>
					</Row>
				</Footer>
				<SplashMessageModal />
			</AntLayout>
		</ConfigProvider>
	);
}
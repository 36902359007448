import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from './components/Pages/Home/PageHome';
import Layout from './components/Layout';
import PageSites from './components/Pages/Sites/PageSites';
import PageDeliveries from './components/Pages/Deliveries/PageDeliveries';
import PageStock from './components/Pages/Stock/PageStock';
import PageDocuments from './components/Pages/Documents/PageDocuments';
import PageTransactions from './components/Pages/Transactions/PageTransactions';
import PageUsers from './components/Pages/Users/PageUsers';
import PageContact from './components/Pages/Contact/PageContact';
import PageDisclaimer from './components/Pages/Disclaimer/PageDisclaimer';
import PageReports from './components/Pages/Reports/PageReports';
import PageSplashMessages from './components/Pages/SplashMessages/PageSplashMessages';
import PageSite from './components/Pages/Sites/PageSite';

export default function () {
    return (
        <Layout >
            <Routes>
                <Route path='/' element={<HomePage />} />
                <Route path='/sites' element={<PageSites />} />

                <Route path='/site/:siteNumber' element={<PageSite />} />

                <Route path="/deliveries" element={<PageDeliveries />} />
                <Route path='/stock' element={<PageStock />} />
                <Route path="/documents" element={<PageDocuments />} />
                <Route path="/transactions" element={<PageTransactions />} />

                <Route path="/users" element={<PageUsers />} />

                <Route path="/contact" element={<PageContact />} />

                <Route path="/disclaimer" element={<PageDisclaimer />} />

                <Route path="/reports" element={<PageReports />} />
                    

                <Route path="/splashmessages" element={<PageSplashMessages />} />

                {/* <ErrorBoundary><Route component={NotificationContainer} /></ErrorBoundary>*/}
            </Routes>
        </Layout>)
}
